// Angular
import { Component, OnInit } from '@angular/core';
import { Breadcrumb, SubheaderService } from '../../../../core/_base/layout/services/subheader.service';

@Component({
	selector: 'vix-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	breadcrumbTitle;
	breadcrumbs: Breadcrumb[] = [];

	constructor(private subheaderService: SubheaderService) { }

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.subheaderService.title$.subscribe(bt => {
			// breadcrumbs title sometimes can be undefined
			if (bt) {
				Promise.resolve(null).then(() => {
					this.breadcrumbTitle = bt.title;
					// this.desc = bt.desc;
				});
			}
		});

		this.subheaderService.breadcrumbs$.subscribe(bc => {
			Promise.resolve(null).then(() => {
				this.breadcrumbs = bc;
			});
		});
	}

}

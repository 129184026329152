// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as npm from '../../package.json';

export const environment = {
	production: false,
	contingencia: false,
	homologacao: false,

	cadastroApiUrl: 'https://dev.erp.api.cadastro.vixsys.com.br',
	comercialApiUrl: 'https://dev.erp.api.comercial.vixsys.com.br',
	conexaoApiUrl: 'https://dev.erp.api.conexao.vixsys.com.br',
	contabilidadeApiUrl: 'https://dev.erp.api.contabilidade.vixsys.com.br',
	documentoApiUrl: 'https://dev.erp.api.documento.vixsys.com.br',
	engenhariaApiUrl:'https://dev.erp.api.engenharia.vixsys.com.br',
	erpIntegracaoApiUrl:'https://dev.erp.api.integracao.vixsys.com.br',
	financeiroApiUrl: 'https://dev.erp.api.financeiro.vixsys.com.br',
	nfeApiUrl: 'https://dev.erp.api.nfe.vixsys.com.br',
	patrimonioApiUrl: 'https://dev.erp.api.patrimonio.vixsys.com.br',
	recursosHumanosApiUrl: 'https://dev.erp.api.recursoshumanos.vixsys.com.br',
	regraNegocioApiUrl: 'https://dev.erp.api.regranegocio.vixsys.com.br',
	relatorioApiUrl: 'https://dev.erp.api.relatorio.vixsys.com.br',
	suprimentosApiUrl: 'https://dev.erp.api.suprimentos.vixsys.com.br',
	vixSysCadastroApiUrl: 'https://dev.comum.api.cadastro.vixsys.com.br',

	criptopHashKey: '5daf20f5Y7e13X46',
	// isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	tinyAPIKey: 'sk5ber6hnch4pcowi51jo0uxay9v1k4kxvzz7cd3brg82rwy',
	localStorageKeys: {
		credenciaisUsuario: 'XDD'
	},
	version: npm.version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { MEN0003Service } from '../../../../../shared/services/01-cadastro/men0003.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'kt-search-dropdown',
	templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent implements OnInit {
	// Public properties
	query: any = {};
	querySemNome: any = {
		'filtros.pageStart': 1,
		'filtros.pageLength': 10000,
		'filtros.favorito': false
	};
	queryNome: any = {
		'filtros.pageStart': 1,
		'filtros.pageLength': 10000,
		'filtros.favorito': false,
		'filtros.nome': ''
	};

	// Forms
	searchForm: FormGroup;

	// Set icon class name
	@Input() icon: string = 'flaticon2-search-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	@Input() type: 'brand' | 'success' | 'warning' = 'success';

	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	data: any[];
	result: any[];
	loading: boolean;

	constructor(private cdr: ChangeDetectorRef,
		public men0003Service: MEN0003Service) {
	}

	ngOnInit(): void {

		this.result = [
			{
				icon: '',
				text: 'Telas Disponíveis',
				type: 0,
				url: ''
			}
		];

		this.carregarMEN0003();
		this.initSearchForm();
	}

	private initSearchForm() {

		this.searchForm = new FormBuilder().group({ Texto: [null, []] });

		this.initSubjects();
	}

	private initSubjects() {

		// this.searchForm.get('Texto').valueChanges.subscribe((value) => {

		// 	this.searchTexto(value);
		// });
	}

	carregarMEN0003(nome?: string) {

		this.data = null;
		this.result = [
			{
				icon: '',
				text: 'Telas Disponíveis',
				type: 0,
				url: ''
			}
		];

		if ((nome != undefined && nome.length) > 0) {
			this.queryNome['filtros.nome'] = nome;
			this.query = this.queryNome;
		}
		else {
			this.query = this.querySemNome;
		}

		this.men0003Service.comboBox0003(this.query).subscribe(listaTelas => {

			for (const item in listaTelas) {

				if (!this.result.find(e => e.text === listaTelas[item].text)) {

					this.result.push({
						icon: '',
						text: listaTelas[item].text,
						type: 1,
						url: listaTelas[item].pagina
					});
				}
			}
		});

		this.data = this.result;
		this.loading = false;
	}

	searchTexto(texto: string) {

		if (texto === undefined || texto.length === 0) {

			texto = this.searchForm.get('Texto').value;
		}

		this.data = null;
		this.loading = true;
		setTimeout(() => {
			this.carregarMEN0003(texto);
			this.data = this.result;
		}, 500);
	}

	clear() {
		this.carregarMEN0003();
		this.searchInput.nativeElement.value = '';
	}

	openChange() {
		setTimeout(() => this.searchInput.nativeElement.focus());
	}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';
import { MEN0003Model } from '../../../views/pages/01-cadastro/men0002/models/men0003.model';

// Providers
import { HttpService } from '../../../core/services/http.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class MEN0003Service implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/men0003/';

	constructor(private http: HttpService) {
	}

	listar(): string {

		return this.baseUrl + '0001';
	}

	cadastrar(model: MEN0003Model): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: MEN0003Model): Observable<any> {

		return this.http.put(this.baseUrl, model, {
			id: model.IDMEN0003
		});
	}

	excluir(model: MEN0003Model): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDMEN0003);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}

	getCombo(filter: any): Observable<any> {

		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	comboBox0003(filter: any): Observable<any> {

		const query: any = {
			...filter
		};

		return this.http.get(this.baseUrl + '0003', query);
	}

	comboBox0003Url(): string {

		return this.baseUrl + '0003';
	}
}

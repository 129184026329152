import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UsuarioFavoritoService {

	private favoritoSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

	constructor() {
	}

	watchFavoritos(): Observable<any[]> {

		return this.favoritoSubject.asObservable();
	}

	atualizarFavoritos(favoritos: any[]) {

		this.favoritoSubject.next(favoritos);
	}

	public get favoritos(): any[] {

		return this.favoritoSubject.value;
	}
}

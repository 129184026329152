// Angular
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface ISearchResult {
	icon: string;
	img: string;
	title: string;
	text: string;
	url: string;
}

@Component({
	selector: 'kt-search-result',
	templateUrl: './search-result.component.html',
	styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent {

	constructor(private router: Router) {
	}

	// Public properties
	@Input() data: ISearchResult[];
	@Input() noRecordText: string;

	navegar(url: string) {

		if (url != undefined) {

			this.router.navigateByUrl(url);
		}
	}
}

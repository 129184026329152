import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Tools } from '../helpers/tools';

@Injectable({
	providedIn: 'root'
})
export class OverlayService {

	constructor(private snackBar: MatSnackBar) { }

	toast(options: { message: string, action?: string, config?: MatSnackBarConfig }) {

		this.snackBar.open(Tools.tratarMensagem(options.message), options.action || 'OK', {
			verticalPosition: 'top',
			panelClass: 'snackBar',
			duration: 2500,
			...options.config
		});
	}

	showSuccessToast() {
		this.toast({ message: 'Sucesso!!' });
	}

	showInvalidFormToast(msg?: string) {
		return this.toast({
			message: msg || 'Por favor, verfique todos os campos marcados em vermelho'
		});
	}

	noIDError(msg?: string): void {

		return this.toast({
			message: msg || 'Clique em salvar primeiro!'
		});
	}

	customToast(durationMiliseconds: number, options: { message: string, action?: string, config?: MatSnackBarConfig }) {

		this.snackBar.open(Tools.tratarMensagem(options.message), options.action || 'OK', {
			verticalPosition: 'top',
			panelClass: 'snackBar',
			duration: durationMiliseconds,
			...options.config
		});
	}

	rotinaResponseToast(rotinResponse: any, options: { message: string, action?: string, config?: MatSnackBarConfig }) {
		if (rotinResponse.Sucesso) {
			options.message = rotinResponse.Mensagem || 'Ação realizada com sucesso!'
		}
		else {
			options.message = rotinResponse.Mensagem || 'Ação não realizada!'
		}

		this.snackBar.open(Tools.tratarMensagem(options.message), options.action || 'OK', {
			verticalPosition: 'top',
			panelClass: 'snackBar',
			duration: 2500,
			...options.config
		});
	}

	persistenteRotinaResponseToast(options: { message: string, action?: string, config?: MatSnackBarConfig }, rotinResponse?: any) {
		if (rotinResponse) {
			if (rotinResponse.Sucesso) {
				options.message = rotinResponse.Mensagem || 'Ação realizada com sucesso!'
			}
			else {
				options.message = rotinResponse.Mensagem || 'Ação não realizada!'
			}
		}

		this.snackBar.open(Tools.tratarMensagem(options.message), options.action || 'OK', {
			verticalPosition: 'top',
			panelClass: 'snackBar',
			duration: 250000,
			...options.config
		});
	}
}

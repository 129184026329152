import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { MatIconModule } from '@angular/material';

@NgModule({
    declarations: [
        AlertComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: [AlertComponent],
    providers: []
})
export class AlertModule { }
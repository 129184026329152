import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsuarioAutenticado } from '../../shared/models/usuario-autenticado.model';

@Injectable({
	providedIn: 'root'
})
export class UsuarioAutenticadoService {

	private _usuarioSubject: BehaviorSubject<any> = new BehaviorSubject<UsuarioAutenticado>(null);

	constructor() {
	}

	public watchUsuarioAutenticado(): Observable<UsuarioAutenticado> {
		return this._usuarioSubject.asObservable();
	}

	public atualizarUsuarioAutenticado(usuario: UsuarioAutenticado) {
		this._usuarioSubject.next(usuario);
	}

	public get usuarioAutenticado(): UsuarioAutenticado {
		return this._usuarioSubject.value;
	}

	public get idUsuario(): number {
		return this.usuarioAutenticado.IDUsuario;
	}

	public get idGrupoEmpresarial(): number {
		return this.usuarioAutenticado.GrupoEmpresarial;
	}

	public get unidadesNegocio(): any[] {
		return this.usuarioAutenticado.UnidadesNegocio;
	}

	public get idEmpresa(): number {
		return this.usuarioAutenticado.Empresa;
	}

	public get permissoes(): any {

		return this.usuarioAutenticado.Permissoes;
	}

}

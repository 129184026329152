export class InputMasks {

	static cpfMask: string = '000.000.000-009';
	static cpfMask2: string = '000.000.000-00';
	static cnpjMask: string = '00.000.000/0000-100';
	static cnpjMask2: string = '00.000.000/0000-00';
	static placaCarroMask: string = 'SSS-0A00';
	static modeloNFE: string = '00';
	static serieNFE: string = '999';
	static chaveNFE: string = '00000000000000000000000000000000000000000000';
	static cepMask: string = '00.000-000';
	static telefoneMask: string = '(00) 0000-00009';
	static telefoneMask2: string = '(00) 00000-0000';
	static UFMask: string = 'SS';
	static catCNHMask: string = 'SS';
	static cNHMask: string = '99999999999999999999';
	static horaMask: string = '00:00';
	static digitoMask: string = '90';
	static margensMask: string = '99999999';
	static currencyOptions: any = { prefix: '', thousands: '.', decimal: ',' };
	static currencyOptionsPrecision2: any = { prefix: '', thousands: '.', decimal: ',', precision: 2 };
	static currencyOptionsPrecision3: any = { prefix: '', thousands: '.', decimal: ',', precision: 3 };
	static currencyOptionsPrecision4: any = { prefix: '', thousands: '.', decimal: ',', precision: 4 };
	static currencyOptionsPrecision5: any = { prefix: '', thousands: '.', decimal: ',', precision: 5 };
	static currencyOptionsPrecision6: any = { prefix: '', thousands: '.', decimal: ',', precision: 6 };
	static currencyOptionsPrecision7: any = { prefix: '', thousands: '.', decimal: ',', precision: 7 };
	static currencyOptionsPrecision8: any = { prefix: '', thousands: '.', decimal: ',', precision: 8 };
	static currencyOptionsPrecision9: any = { prefix: '', thousands: '.', decimal: ',', precision: 9 };
	static currencyOptionsPrecision10: any = { prefix: '', thousands: '.', decimal: ',', precision: 10 };
	static currencyOptionsPrecision11: any = { prefix: '', thousands: '.', decimal: ',', precision: 11 };
	static currencyOptionsPrecision12: any = { prefix: '', thousands: '.', decimal: ',', precision: 12 };
	static percentualMask: any = { prefix: '', thousands: '', decimal: ',', precision: 2, suffix: '%' };
	static percentualOptionsPrecision4: any = { prefix: '', thousands: '', decimal: ',', precision: 4, suffix: '%' };
	static parcelaMask: string = '999';
	static quantidadeMask: any = { prefix: '', thousands: '.', decimal: ',', precision: 3 };
	static dateMask: string = '00/00/0000';
}

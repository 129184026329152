import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

// Providers
import { LoaderService } from './services/loader.service';

@Component({
	selector: 'kt-loader',
	template: '<ngx-loading [show]="loading | async"></ngx-loading>',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	loading: Subject<boolean> = this.loaderService.isLoading;

	constructor(private loaderService: LoaderService) { }

	ngOnInit(): void {
	}

}



export class StorageService {

	constructor() {}


	static save(key: string, value: any) {
		return localStorage.setItem(key, value);
	}

	static get(key: string) {
		return localStorage.getItem(key);
	}

	static clear() {
		return localStorage.clear();
	}

	static clearKey(key: string) {

		return localStorage.removeItem(key);
	}
}

import { TokenStorage } from './../../../../core/services/token-storage.service';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Auth
import { AuthService } from '../../../../core/services/auth.service';
import { environment } from '../../../../../environments/environment';
import { OverlayService } from '../../../../core/services/overlay.service';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	lembrarCredencias = false;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private tokenStorage: TokenStorage,
		private overlayService: OverlayService,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			// this.returnUrl = params['returnUrl'] || '/';
			this.returnUrl = '/';
		});

		let credenciais: any = localStorage.getItem(environment.localStorageKeys.credenciaisUsuario);
		if (credenciais) {
			credenciais = JSON.parse(credenciais);
			this.loginForm.patchValue({ login: credenciais.Login, palavraChave: credenciais.PalavraChave });
			this.lembrarCredencias = true;
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		this.loginForm = this.fb.group({
			login: ['', Validators.compose([
				Validators.required
			])
			],
			password: ['', Validators.compose([
				Validators.required
			]),
			],
			palavraChave: ['', Validators.compose([
				Validators.required
			]),
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {

		// tslint:disable-next-line: indent
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			Login: controls.login.value,
			Senha: controls.password.value,
			PalavraChave: controls.palavraChave.value
		};
		this.auth.login(authData)
			.pipe(
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				}),
				takeUntil(this.unsubscribe)
			).subscribe(user => {

				this.tokenStorage.setAccessToken(user.Token);
				localStorage.setItem(environment.localStorageKeys.credenciaisUsuario, JSON.stringify({
					Login: authData.Login,
					PalavraChave: authData.PalavraChave
				}));
				this.router.navigateByUrl(this.returnUrl); // Main page
			}, error => {
				this.overlayService.toast({ message: error.error });
			});
	}

	setUserRoles(permissoes: any[]) {

		if (permissoes === null) {
			return {};
		}

		const roles = {};

		for (const item of permissoes) {

			roles[item.IDFuncionalidade] = {
				Ler: item.Ler,
				Salvar: item.Salvar,
				Apagar: item.Apagar,
				DemaisOperacoes: item.DemaisOperacoes
			};
		}

		this.tokenStorage.setUserRoles(roles);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

}

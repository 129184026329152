import { NgModule } from '@angular/core';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ToolbarComponent } from './toolbar.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		ToolbarComponent,
	],
	imports: [
		CommonModule,
		KeyboardShortcutsModule.forRoot(),
		NgbModule
	],
	exports: [ToolbarComponent],
	providers: []
})
export class ToolbarModule { }

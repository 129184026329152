import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { MenuAsideService } from '../_base/layout';
import { UsuarioAutenticadoService } from './usuario-autenticado.service';
import { UnidadeNegocio } from '../../shared/models/unidade-negocio.model';

// https://stackoverflow.com/questions/41859189/angular2-observable-how-to-call-next-from-outside-of-the-observables-construc
@Injectable({
	providedIn: 'root'
})
export class UnidadeNegocioController {

	private _unidadesBehaviorSubject: BehaviorSubject<UnidadeNegocio[]> = new BehaviorSubject([]);
	private _unidadeAtualBehaviorSubject: BehaviorSubject<UnidadeNegocio> = new BehaviorSubject(null);

	private menuItems = [];

	constructor(
		private usuarioAutenticadoService: UsuarioAutenticadoService,
		private router: Router,
		private menuAsideService: MenuAsideService) {

		this.usuarioAutenticadoService.watchUsuarioAutenticado().subscribe(usuario => {
			if (usuario) {
				this._unidadesBehaviorSubject.next(usuario.UnidadesNegocio);
				const unidadeUsuario = this.getOnStorage();
				if (unidadeUsuario && unidadeUsuario.IDUsuario === usuario.IDUsuario) {
					this.alterarUnidadeNegocio(unidadeUsuario.IDUnidadeNegocio);
				} else {
					this.alterarUnidadeNegocio(usuario.UnidadeNegocio);
				}
			} else {
				this._unidadesBehaviorSubject.next([]);
				this._unidadeAtualBehaviorSubject.next(null);
			}
		});

		this.menuAsideService.menuList$.subscribe(r => {
			this.menuItems = r;
		});
	}

	public watchUnidades(): Observable<UnidadeNegocio[]> {
		return this._unidadesBehaviorSubject.asObservable();
	}

	public watchUnidadeAtual(): Observable<UnidadeNegocio> {
		return this._unidadeAtualBehaviorSubject.asObservable();
	}

	public get unidadeNegocioAtual(): UnidadeNegocio {
		return this._unidadeAtualBehaviorSubject.value;
	}

	public get idUnidadeNegocio(): number {
		return this.unidadeNegocioAtual.IDUnidadeNegocio;
	}
	public get idEmpresa(): number {
		return this.unidadeNegocioAtual.IDEmpresa;
	}

	public alterarUnidadeNegocio(idUnidadeNegocio: number) {
		const unidades = this._unidadesBehaviorSubject.value;
		const unidade = unidades.find(u => u.IDUnidadeNegocio == idUnidadeNegocio);
		if (unidade) {
			this._unidadeAtualBehaviorSubject.next(unidade);
			this.saveOnStorage(unidade);
		}
	}

	private saveOnStorage(unidadeNegocio: UnidadeNegocio) {
		localStorage.setItem('VIX_USUARIO_UNIDADE', JSON.stringify({
			IDUsuario: this.usuarioAutenticadoService.idUsuario,
			...unidadeNegocio
		}));
	}

	private getOnStorage() {
		const unidade = localStorage.getItem('VIX_USUARIO_UNIDADE');
		return unidade ? JSON.parse(unidade) : null;
	}

	// private getUrlItemMenuSelecionado() {
	// 	let itemMenuSelecionado;
	// 	for (let i of this.menuItems) {
	// 		itemMenuSelecionado = this.getMenuItemActive(i) || itemMenuSelecionado;
	// 	}
	// 	return itemMenuSelecionado ? itemMenuSelecionado.page : null;
	// }

	private getMenuItemActive(item) {
		if (item.submenu) {
			return this.getMenuRootItemActive(item);
		}

		if (!item.page) {
			return null;
		}

		return this.router.url.split(/[?#]/)[0].indexOf(item.page) !== -1 ? item : null;
	}

	private getMenuRootItemActive(item) {
		let r = null;

		for (const subItem of item.submenu) {
			r = this.getMenuItemActive(subItem) || r;
		}

		return r;
	}

}

export class NfeV001CompModel {

	versao: string = null;
	tpAmb: number = null;
	verAplic: string = null;
	cStat: string = null;
	xMotivo: string = null;
	cUF: number = null;
	dhRecbto: string = null;
	tMed: string = null;
	chNFe: string = null;
	nProt: string = null;
	digVal: string = null;
	Id: number = null;
	idLote: number = null;
	cOrgao: number = null;
	tpEvento: string = null;
	nSeqEvento: string = null;
	CNPJDest: string = null;
	emailDest: string = null;
	dhRegEvento: string = null;
	byteArray: string = null;
	dhEvento: string = null;
	verEvento: string = null;
	descEvento: string = null;
	xJust: string = null;
	xCorrecao: string = null;
	CNPJ: string = null;

	// Dados Evento Cancelamento
	cOrgaoCan: number = null;
	tpAmbCan: number = null;
	CNPJCan: string = null;
	chNFeCan: string = null;
	dhEventoCan: string = null;
	tpEventoCan: string = null;
	nSeqEventoCan: string = null;
	verEventoCan: string = null;
	descEventoCan: string = null;
	xJustCan: string = null;

	cStatCan: string = null;
	dhRegEventoCan: string = null;
	nProtCan: string = null;
	CNPJDestCan: string = null;
	emailDestCan: string = null;
	xMotivoCan: string = null;

	// Dados Evento CCe
	cOrgaoCCe: number = null;
	tpAmbCCe: number = null;
	CNPJCCe: string = null;
	chNFeCCe: string = null;
	dhEventoCCe: string = null;
	tpEventoCCe: string = null;
	nSeqEventoCCe: string = null;
	verEventoCCe: string = null;
	descEventoCCe: string = null;
	xCorrecaoCCe: string = null;

	cStatCCe: string = null;
	dhRegEventoCCe: string = null;
	nProtCCe: string = null;
	CNPJDestCCe: string = null;
	emailDestCCe: string = null;
	xMotivoCCe: string = null;

	// Dados Evento Manifesto Destinatário
	cOrgaoMani: number = null;
	tpAmbMani: number = null;
	CNPJMani: string = null;
	chNFeMani: string = null;
	dhEventoMani: string = null;
	tpEventoMani: string = null;
	nSeqEventoMani: string = null;
	verEventoMani: string = null;
	descEventoMani: string = null;
	xJustMani: string = null;

	cStatMani: string = null;
	dhRegEventoMani: string = null;
	nProtMani: string = null;
	CNPJDestMani: string = null;
	emailDestMani: string = null;
	xMotivoMani: string = null;

	constructor(init?: Partial<NfeV001CompModel>) {
		Object.assign(this, init);
	}
}

import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

// Providers
import { AvisoService } from '../../../../../shared/services/01-cadastro/aviso.service';

export interface IAvisosData {
	time: string;
	text: string;
	icon?: string;
	attachment?: string;
}

@Component({
	selector: 'kt-dash-avisos',
	templateUrl: './dash-avisos.component.html',
	styleUrls: ['./dash-avisos.component.scss']
})
export class DashAvisosComponent implements OnInit {
	// Public properties
	@Input() data: IAvisosData[];


	constructor(private avisoService: AvisoService,
		private cdr: ChangeDetectorRef) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (!this.data) {
			this.data = [
				{
					time: '10:00',
					icon: 'fa fa-genderless kt-font-danger',
					text: 'Atividade 1',
				},
				{
					time: '12:45',
					icon: 'fa fa-genderless kt-font-success',
					text: 'Atividade 2',
				},
				{
					time: '14:00',
					icon: 'fa fa-genderless kt-font-brand',
					text: 'Atividade 3',
				},
				{
					time: '17:00',
					icon: 'fa fa-genderless kt-font-info',
					text: 'Atividade 4',
				},
			];
		}

		this.carregarAvisos();
	}

	private carregarAvisos(): void {
		this.avisoService.listar0002({ 'filtros.pageLength': 4 }).subscribe((resp) => {
			this.data = [];

			for (const item of resp.data) {
				this.data.push({
					time: new Date(item.DataInicio).getDate() + '/' + (new Date(item.DataInicio).getMonth() + 1),
					text: item.Descricao,
					icon: 'fa fa-genderless kt-font-info',
				});
			}

			this.cdr.markForCheck();
		});
	}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { TokenStorage } from '../../../core/services/token-storage.service';
import { UsuarioFuncionalidadeService } from '../../../core/services/usuario-funcionalidade.service';

// Interfaces
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class FuncionalidadeService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/fun0001/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage,
		private usuarioFuncionalidadeService: UsuarioFuncionalidadeService) { }


	listar(): string {
		throw new Error('Method not implemented.');
	}

	cadastrar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	editar(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	excluir(model: any): Observable<any> {
		throw new Error('Method not implemented.');
	}

	visualizar(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}

	getFuncionalidades(filter?: any): Observable<any> {

		const query = {
			'filtros.iDUnidadeNegocio': this.tokenStorage.getUnidadeUser() ? this.tokenStorage.getUnidadeUser().IDUnidadeNegocio : null,
			'filtros.iDUsuario': this.tokenStorage.getUnidadeUser() ? this.tokenStorage.getUnidadeUser().IDUsuario : null,
			...filter
		};

		return this.http.get(this.baseUrl + '0001', query);
	}

	atualizarUsuarioFuncionalidades(filter?: any) {

		this.getFuncionalidades(filter).subscribe((response) => {

			this.usuarioFuncionalidadeService.atualizarFuncionalidades(response);
		});
	}


}

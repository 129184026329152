import { Component, OnInit } from '@angular/core';
import { UnidadeNegocioController } from '../../../../core/services/unidade-negocio-controller.service'
import { UnidadeNegocio } from '../../../../shared/models/unidade-negocio.model';
import { AuthService } from '../../../../core/services/auth.service';
import { LoaderService } from '../../../../shared/components/loader/services/loader.service';

@Component({
	selector: 'kt-select-empresa',
	template: `<ng-select class="selectEmpresa" [(ngModel)]="idUnidadeNegocioAtual"
							[items]="unidades" bindLabel="RazaoSocial" bindValue="IDUnidadeNegocio"
							placeholder="Selecione a Unidade" [clearable]="false"
							(change)="alterarUnidadeNegocio(idUnidadeNegocioAtual)">
						</ng-select>`,
	styleUrls: ['./select-empresa.component.scss']
})
export class SelectEmpresaComponent implements OnInit {
	unidades: UnidadeNegocio[];
	idUnidadeNegocioAtual: number;

	constructor(public unidadeNegocioController: UnidadeNegocioController,
		private authService: AuthService,
		private loaderService: LoaderService) { }

	ngOnInit() {
		this.unidadeNegocioController.watchUnidades().subscribe(unidades => {
			this.unidades = unidades;
		});

		this.unidadeNegocioController.watchUnidadeAtual().subscribe(unidadeAtual => {
			if (unidadeAtual) {
				this.idUnidadeNegocioAtual = unidadeAtual.IDUnidadeNegocio;
			}
		});
	}

	alterarUnidadeNegocio(idUnidadeNegocio: number) {
		this.loaderService.show();
		this.authService.reautenticar(idUnidadeNegocio, this.loaderService, window.location.href);
	}
}

import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SweetAlertResult } from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class SwalService {

	constructor() { }

	successSwal(message: string = 'Sucesso') {
		return Swal.fire({
			title: 'Sucesso!',
			text: message,
			icon: 'success',
			timer: 999999,
			allowEnterKey: true
		});
	}

	confirmSwal(message: string = 'Tem certeza que deseja fazer isso ?') {

		return Swal.fire({
			title: 'Confirmação',
			text: message,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Confirmar',
		});
	}

	fireSwal(options: SweetAlertOptions) {

		return Swal.fire({
			showCancelButton: true,
			...options
		});
	}

	errorSwal(title: string, text: string) {
		return Swal.fire({
			title,
			text,
			icon: 'warning',
			showCancelButton: false,
			confirmButtonText: 'Confirmar'
		});
	}

	swalWithInput(options?: SweetAlertOptions): Promise<SweetAlertResult> {

		return Swal.fire({
			...options,
			inputAttributes: {
				autocapitalize: 'off'
			},
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Confirmar',
		});
	}

	customSwal(message: string, title: string, confirmButtonText: string, showCancelButton: boolean, cancelButtonText: string) {

		return Swal.fire({
			title: title,
			text: message,
			icon: 'warning',
			showCancelButton: showCancelButton,
			cancelButtonText: cancelButtonText,
			confirmButtonText: confirmButtonText,
		});
	}

	customSwalCSS(message: string, title: string, confirmButtonText: string, showCancelButton: boolean, cancelButtonText: string) {
		return Swal.fire({
			title: title,
			text: message,
			icon: 'warning',
			customClass: {
				container: 'custom-swal-container',
				popup: 'custom-swal-popup',
			},
			width: '200px', // largura personalizada
			heightAuto: false,
			showCancelButton: showCancelButton,
			cancelButtonText: cancelButtonText,
			confirmButtonText: confirmButtonText,
		});
	}
}

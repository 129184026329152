import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { AvisoModel } from '../../../views/pages/01-cadastro/aviso/models/aviso.model';
import { environment } from '../../../../environments/environment';

// Providers
import { HttpService } from '../../../core/services/http.service';
import { TokenStorage } from '../../../core/services/token-storage.service';

// Interface
import { IBaseService } from '../../../core/interfaces/base-service.interface';

@Injectable({
	providedIn: 'root'
})
export class AvisoService implements IBaseService {

	readonly baseUrl: string = environment.cadastroApiUrl + '/api/aviso/';

	constructor(private http: HttpService,
		private tokenStorage: TokenStorage) {
	}

	listar(): string {

		return this.baseUrl + '0001';
	}

	listarObservable(filter?): Observable<any> {

		const query = {
			'filtros.pageStart': 0,
			'filtros.pageLength': 100,
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0001', query);
	}

	listar0002(filter?: any): Observable<any> {

		const query = {
			'filtros.pageStart': 0,
			'filtros.pageLength': 100,
			'filtros.iDEmpresa': this.tokenStorage.getUnidadeUser().IDEmpresa,
			...filter
		};

		return this.http.get(this.baseUrl + '0002', query);
	}

	cadastrar(model: AvisoModel): Observable<any> {

		return this.http.post(this.baseUrl, model);
	}

	editar(model: AvisoModel): Observable<any> {
		const query: any = {
			id: model.IDAviso
		};
		return this.http.put(this.baseUrl, model, query);
	}

	excluir(model: AvisoModel): Observable<any> {

		return this.http.delete(this.baseUrl + model.IDAviso);
	}

	visualizar(id: number): Observable<any> {

		return this.http.get(this.baseUrl + id);
	}
}

import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShortcutInput, ShortcutEventOutput } from 'ng-keyboard-shortcuts';

// Components
import { DataTableComponent } from '../data-table/data-table.component';

// Providers
import { OverlayService } from '../../../core/services/overlay.service';
import { EncryptService } from './../../../core/services/encrypt.service';
import { SwalService } from '../../../core/services/swal.service';

interface IToolbarAcao {
	handler?: (...any) => any;
}

interface IToolbarMultiAcao {
	handlers?: Array<{ [key: string]: (...any) => any }>;
}

export interface IToolbarAcoes {
	tablePrimaryKey?: string;
	cadastrar?: IToolbarAcao;
	adicionar?: IToolbarAcao;
	salvar?: IToolbarAcao;
	editar?: IToolbarAcao;
	excluir?: IToolbarAcao;
	visualizar?: IToolbarAcao;
	voltar?: IToolbarAcao;
	finalizar?: IToolbarAcao;
	estornar?: IToolbarAcao;
	consultaPublica?: IToolbarAcao;
	consultar?: IToolbarAcao;
	importar?: IToolbarAcao;
	baixar?: IToolbarMultiAcao;
	relatorio?: IToolbarMultiAcao;
	customOpt?: IToolbarMultiAcao;
	imprimir?: IToolbarMultiAcao;
	enviarEmail?: IToolbarAcao;
	acoes?: IToolbarMultiAcao;
	nfe?: IToolbarMultiAcao;
	tarefa1?: IToolbarMultiAcao;
	tarefa2?: IToolbarMultiAcao;
	nfse?: IToolbarMultiAcao;
	integracaoBancaria?: IToolbarMultiAcao;
	remessaBancaria?: IToolbarMultiAcao;
}

@Component({
	selector: 'toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

	@Input() set acoes(value: IToolbarAcoes) {
		this._acoes = value ? value : {};
	}
	@Input('table') dtComponent: DataTableComponent;

	@Input() disableVoltar: boolean = false;

	public _acoes: IToolbarAcoes = {};
	private _acoesHabilitadas: boolean = true;

	shortcuts: ShortcutInput[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private overlayService: OverlayService,
		private encryptService: EncryptService,
		private swalService: SwalService) {
	}

	ngOnInit() {
		this.configurarAtalhosTeclado();
	}

	get dtInstance(): Promise<DataTables.Api> {
		return this.dtComponent ? this.dtComponent.dtInstance : null;
	}

	cadastrar() {
		if (this._acoes.cadastrar && this._acoes.cadastrar.handler) {
			return this._acoes.cadastrar.handler();
		}

		const path = this.activatedRoute.snapshot.url[0] ? this.activatedRoute.snapshot.url[0].path : null;

		if (path == 'editar' || path == 'visualizar') {
			this.router.navigate(['../../cadastrar'], { replaceUrl: true, relativeTo: this.activatedRoute });
		} else {
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate(['cadastrar'], { relativeTo: this.activatedRoute }).then(() => {
				this.router.onSameUrlNavigation = 'ignore';
			});
		}
	}

	adicionar() {
		if (this._acoes.adicionar && this._acoes.adicionar.handler) {
			return this._acoes.adicionar.handler();
		}

		const path = this.activatedRoute.snapshot.url[0] ? this.activatedRoute.snapshot.url[0].path : null;

		if (path == 'cadastrar') {

			window.location.reload();
			return;
		}

		if (path == 'editar' || path == 'visualizar') {
			this.router.navigate(['../../cadastrar'], { replaceUrl: true, relativeTo: this.activatedRoute });
		} else {
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate(['../cadastrar'], { relativeTo: this.activatedRoute }).then(() => {
				this.router.onSameUrlNavigation = 'ignore';
			});
		}
	}

	excluir() {
		if (this.dtInstance) {
			this.getObjetosSelecionado().subscribe((dataArray) => {
				dataArray.length !== 0 ? this._acoes.excluir.handler(dataArray)
					: this.overlayService.toast({ message: 'Selecione um Item!' });
			});

		} else {
			this._acoes.excluir.handler();
		}
	}

	salvar() {
		this._acoes.salvar.handler();
	}

	editar() {
		if (this._acoes.editar && this._acoes.editar.handler) {
			if (this.dtInstance) {
				this.getObjetoSelecionado().then(o => {
					o ?
						this._acoes.editar.handler(o)
						:
						this.overlayService.toast({ message: 'Selecione um Item!' });
				});
				return;
			} else {

				return this._acoes.editar.handler();
			}

			// return this._acoes.editar.handler();
		}
		if (this.dtInstance) {
			this.getObjetoSelecionado().then(o => {
				o ?
					this.router.navigate(['editar', this.criptoID(o[this._acoes.tablePrimaryKey])], { relativeTo: this.activatedRoute })
					:
					this.overlayService.toast({ message: 'Selecione um Item!' });
			});
		} else {

			this.router.navigate(['editar', this.criptoID(this.activatedRoute.snapshot.params.id)],
				{ replaceUrl: true, relativeTo: this.activatedRoute });
		}
	}

	visualizar() {
		if (this._acoes.visualizar && this._acoes.visualizar.handler) {
			return this._acoes.visualizar.handler();
		}
		this.getObjetoSelecionado().then(o => {
			o ?
				this.router.navigate(['visualizar', this.criptoID(o[this._acoes.tablePrimaryKey])], { relativeTo: this.activatedRoute })
				:
				this.overlayService.toast({ message: 'Selecione um Item!' });
		});
	}

	consultar() {
		if (this._acoes.consultar && this._acoes.consultar.handler) {
			return this._acoes.visualizar.handler();
		}
		this.getObjetoSelecionado().then(o => {
			o ?
				this.router.navigate(['visualizar', this.criptoID(o[this._acoes.tablePrimaryKey])], { relativeTo: this.activatedRoute })
				:
				this.overlayService.toast({ message: 'Selecione um Item!' });
		});
	}

	importar() {
		if (this._acoes.importar && this._acoes.importar.handler) {
			return this._acoes.importar.handler();
		}
		this.getObjetoSelecionado().then(o => {
			o ?
				this.router.navigate(['importar', this.criptoID(o[this._acoes.tablePrimaryKey])], { relativeTo: this.activatedRoute })
				:
				this.overlayService.toast({ message: 'Selecione um Item!' });
		});
	}

	voltar() {
		this.router.navigate([this.activatedRoute.snapshot.parent.routeConfig.path]);
	}

	finalizar() {
		if (this._acoes.finalizar && this._acoes.finalizar.handler) {
			return this._acoes.finalizar.handler();
		}
	}

	estornar() {
		if (this._acoes.estornar && this._acoes.estornar.handler) {
			return this._acoes.estornar.handler();
		}
	}

	enviarEmail() {
		if (this._acoes.enviarEmail && this._acoes.enviarEmail.handler) {
			return this._acoes.enviarEmail.handler();
		}
	}

	consultaPublica() {
		if (this._acoes.consultaPublica && this._acoes.consultaPublica.handler) {
			return this._acoes.consultaPublica.handler();
		}
	}

	private async getObjetoSelecionado(): Promise<any> {
		return this.dtInstance.then(dtInstance => {
			let dataRow = dtInstance.rows({ selected: true }).data()[0];
			return dataRow;
		});
	}

	private getObjetosSelecionado(): Observable<any[]> {
		return this.dtComponent.getSelectedItens();
	}

	private configurarAtalhosTeclado() {
		if (this._acoes.cadastrar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + n',
					description: 'Novo',
					command: (output: ShortcutEventOutput) => this.cadastrar(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.adicionar && !this._acoes.cadastrar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + n',
					description: 'Novo',
					command: (output: ShortcutEventOutput) => this.adicionar(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.salvar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + s',
					description: 'Salvar',
					command: (output: ShortcutEventOutput) => this.salvar(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.editar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + e',
					description: 'Editar',
					command: (output: ShortcutEventOutput) => this.editar(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.excluir) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + del',
					description: 'Excluir',
					command: (output: ShortcutEventOutput) => this.excluir(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.visualizar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + v',
					description: 'Visualizar',
					command: (output: ShortcutEventOutput) => this.visualizar(),
					preventDefault: true
				}
			);
		}

		this.shortcuts.push(
			{
				key: 'alt + ctrl + b',
				description: 'Voltar',
				command: (output: ShortcutEventOutput) => this.voltar(),
				preventDefault: true
			}
		);

		if (this._acoes.finalizar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + t',
					description: 'Finalizar',
					command: (output: ShortcutEventOutput) => this.finalizar(),
					preventDefault: true
				}
			);
		}

		if (this._acoes.estornar) {
			this.shortcuts.push(
				{
					key: 'alt + ctrl + d',
					description: 'Estornar',
					command: (output: ShortcutEventOutput) => this.estornar(),
					preventDefault: true
				}
			);
		}

	}

	public habilitarAcoes() {
		this._acoesHabilitadas = true;
	}

	public desabilitarAcoes() {
		this._acoesHabilitadas = false;
	}

	isDisabled(acao) {
		return !this._acoesHabilitadas;
	}

	criptoID(value: any) {
		return this.encryptService.base64Encrypt(value);
	}

	getObjKeys(obj: object) {
		return Object.keys(obj);
	}

	getHandler(obj: object) {
		return Object.values(obj)[0]();
	}

	getHandlerName(obj: object) {
		return Object.keys(obj)[0];
	}

}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { Tools } from '../helpers/tools';
import { StorageService } from './storage.service';

@Injectable()
export class TokenStorage {

	private cryptoKey = '3RP51MP135';

	public getAccessToken(): string {

		const token: any = StorageService.get('accessToken');


		// const decrypted = CryptoJS.AES.decrypt(token, this.cryptoKey);
		// let decryptedText: any;
		// try {
		// 	decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
		// } catch (e) {

		// }

		// if (Tools.isJsonString(decryptedText) == false) {

		// }

		return token;
	}


	public getUserRoles(): Observable<any> {
		// const roles: any = localStorage.getItem('userRoles');
		// try {
		// 	return of(JSON.parse(roles));
		// } catch (e) {}

		const roles: any = StorageService.get('userRoles');

		if (Tools.isJsonString(roles)) {
			return of(JSON.parse(roles));
		}

		// If is not

		const decrypted = CryptoJS.AES.decrypt(roles, this.cryptoKey);
		let decryptedText: any;
		try {
			decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
		} catch (e) {

		}

		if (Tools.isJsonString(decryptedText) == false) {

		}

		const rights = JSON.parse(decryptedText);

		return of(rights);
	}


	public getUnidades(): Observable<any> {

		const decrypted = CryptoJS.AES.decrypt(StorageService.get('unidades'), this.cryptoKey);
		let decryptedText: any;

		try {
			decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
		} catch (e) {

		}


		return of(JSON.parse(decryptedText));
	}



	public getUser(): Observable<any> {

		const decrypted = CryptoJS.AES.decrypt(StorageService.get('user'), this.cryptoKey);
		let decryptedText: any;

		try {
			decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
		} catch (e) {

		}


		return of(JSON.parse(decryptedText));
	}

	public getUnidadeUser(): { IDUsuario: number, IDEmpresa: number, IDUnidadeNegocio: number, RazaoSocial: string } {

		// const decrypted = CryptoJS.AES.decrypt(StorageService.get('VIX_USUARIO_UNIDADE'), this.cryptoKey);
		// let decryptedText: any;

		// try {
		// 	decryptedText =  decrypted.toString(CryptoJS.enc.Utf8);
		// } catch (e) {

		// }

		// console.log('decripted unidades', decryptedText);

		return JSON.parse(StorageService.get('VIX_USUARIO_UNIDADE'));
	}


	public setAccessToken(token: string): TokenStorage {
		// localStorage.setItem('accessToken', token);

		StorageService.save('accessToken', token);

		return this;
	}

	public setUnidadesNegocio(unidades: any): any {
		// if (roles != null) {
		// 	localStorage.setItem('userRoles', JSON.stringify(roles));
		// }

		StorageService.save('unidades', CryptoJS.AES.encrypt(JSON.stringify(unidades), this.cryptoKey));

		return this;
	}


	public setUserRoles(roles: any): any {
		// if (roles != null) {
		// 	localStorage.setItem('userRoles', JSON.stringify(roles));
		// }

		StorageService.save('userRoles', CryptoJS.AES.encrypt(JSON.stringify(roles), this.cryptoKey));

		return this;
	}

	public setUnidadeUser(unidade: any): any {

		StorageService.save('VIX_USUARIO_UNIDADE', CryptoJS.AES.encrypt(JSON.stringify(unidade), this.cryptoKey));

		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		// this.router.navigateByUrl(this.returnUrl); // Main page
		// StorageService.clear();

		// StorageService.clearKey('user');
		StorageService.clearKey('userRoles');
		StorageService.clearKey('accessToken');
		StorageService.clearKey('VIX_USUARIO_UNIDADE');
	}

	encriptation() {

		let encrypted = CryptoJS.AES.encrypt(JSON.stringify({ "IDTEX0001": 25, "Nome": "uil" }), CryptoJS.enc.Utf8.parse('5daf20f5Y7e13X46'), {

			keySize: 128,
			BlockSize: 128,
			iv: CryptoJS.enc.Utf8.parse('5daf20f5Y7e13X46'),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});

		return encrypted.toString();
		// let encrypted = CryptoJS.AES.encrypt(JSON.stringify({
		// 	"IDTEX0001": 25,
		// 	"Nome": "uil"
		// }))

		// return JSON.stringify({
		// 	"IDTEX0001": 25,
		// 	"Nome": "uil"
		// });

		// "h1XmVkjI3Js09MQrzaRnUsygUMvWwlG7GZoHWIDtX3M="

		// return CryptoJS.AES.decrypt('h1XmVkjI3Js09MQrzaRnUsygUMvWwlG7GZoHWIDtX3M=', '5daf20f5Y7e13X46');

		// const str = { "IDCTR0001": 0, "IDUnidadeNegocio": 21, "Numero": 10, "Nome": "CTR0001 0001", "Ativo": true };

		// console.log('teste', Tools.toUTF8Array('5daf20f5Y7e13X46').toString());


		// // let encrypted = CryptoJS.AES.encrypt("Message", key, { iv: iv }).toString();

		// return CryptoJS.AES.encrypt(JSON.stringify(str), '5daf20f5Y7e13X46', {
		// 	mode: CryptoJS.mode.CBC,
		// 	padding: CryptoJS.pad.Pkcs7,
		// 	iv: '5daf20f5Y7e13X46'
		// }).toString();
	}
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable, from } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Providers
import { EncryptService } from './../../../core/services/encrypt.service';
import { UsuarioAutenticadoService } from '../../..//core/services/usuario-autenticado.service';
import { OverlayService } from '../../../core/services/overlay.service';
import { AuthService } from '../../../core/services/auth.service';
import { LoaderService } from '../loader/services/loader.service';

@Component({
	selector: 'kt-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(DataTableDirective, { static: true })
	datatableElement: DataTableDirective;

	dtOptions;
	dtTrigger: Subject<any> = new Subject();
	resizeSubject: Subject<any> = new Subject();

	// Inputs Vars
	@Input() serverSide: boolean = true;
	@Input() displayFooter: boolean = false;
	@Input() tableOverflow: boolean = true;
	@Input() tableResponsiveClass: boolean = true;
	@Input() tableFixedClass: boolean = false;
	@Input() set options(options) {
		if (options) {
			let dtOptions = {
				dom: `<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>
			  <'row'<'col-sm-12'tr>>
			  <'row'<'col-sm-12 col-md-4'i><'col-sm-12 col-md-5'p>>`,
				searching: !options.searching ? false : options.searching,
				ordering: !options.ordering ? false : options.ordering,
				lengthChange: !options.lengthChange ? false : options.lengthChange,
				pageLength: !options.pageLength ? 20 : options.pageLength,
				...options.dtOptions,
				serverSide: this.serverSide,
				// serverSide: false,
				pagingType: 'full_numbers',
				scrollX: true,
				// data: [
				// 	[ "Tiger Nixon", "System Architect", "Edinburgh", "5421", "2011/04/25", "$320,800" ],
				// 	[ "Garrett Winters", "Accountant", "Tokyo", "8422", "2011/07/25", "$170,750" ],
				// ],
				// columns: [
				// 	{ title: "Name" },
				// 	{ title: "Position" },
				// 	{ title: "Office" },
				// 	{ title: "Extn." },
				// 	{ title: "Start date" },
				// 	{ title: "Salary" },
				// ],
				ajax: {
					url: options.requestUrl,
					type: 'GET',
					beforeSend: (request) => {
						request.setRequestHeader('Token', this.usuarioAutenticadoService.usuarioAutenticado.Token);
						this.loaderService.show();
					},
					data: (d) => {
						return {
							...options.requestBody,
							'filtros.pageStart': d.start,
							'filtros.pageLength': d.length
						};
					},
					dataSrc: (json) => { this.loaderService.hide(); return json.data; },
					start: 0,
					length: 10,
					error: (reason) => {
						this.loaderService.hide();

						if (reason.status !== 200) {
							this.overlayService.toast({ message: reason.responseText });
							this.overlayService.toast({ message: reason.responseJSON.Message });
						}

						if (reason.status === 401) {
							this.auth.logout();
						}
					}
				},
				language: this.dataTableLanguage,
				colReorder: false
			};
			if (options.primaryKey) {
				dtOptions.rowCallback = (row: Node, data: any) => {
					$('td', row).off('dblclick');
					$('td', row).on('dblclick', () => {
						this.router.navigate(['visualizar', this.criptoID(data[options.primaryKey])], { relativeTo: this.activatedRoute });
					});
				};
			}
			if (options.checkbox) {
				dtOptions = {
					...dtOptions,
					columnDefs: [
						{
							targets: 0,
							checkboxes: {
								selectRow: true
							}
						}
					],
					select: {
						style: 'multi'
					},
				};
			}
			this.dtOptions = dtOptions;
			this.rerender();
		}
	}

	constructor(
		private usuarioAutenticadoService: UsuarioAutenticadoService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private cryptoService: EncryptService,
		private overlayService: OverlayService,
		private loaderService: LoaderService,
		private auth: AuthService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.initSubjects();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}

	ngOnDestroy() {
		this.resizeSubject.unsubscribe();
	}

	initSubjects() {
		this.resizeSubject.pipe(
			debounceTime(200)
		).subscribe((value) => {
			this.dtInstance.then((table) => {
				table.columns.adjust();
				this.cdr.detectChanges();
			});
		});
	}

	get dtInstance(): Promise<DataTables.Api> {
		return this.datatableElement ? this.datatableElement.dtInstance : null;
	}

	rerender(): void {
		if (this.dtInstance) {
			this.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
				this.dtTrigger.next();
			});
		}
	}

	getSelectedItens(): Observable<any[]> {
		return from(
			this.dtInstance.then((instance: DataTables.Api) => {
				const dataArray = [];
				const instanceData = instance.rows({ selected: true }).data();

				for (const key in instanceData) {
					if (!isNaN(Number(key))) {
						dataArray.push(instanceData[key]);
						continue;
					} else {
						break;
					}
				}
				return dataArray;
			})
		);
	}

	getData(): Observable<any[]> {
		return from(
			this.dtInstance.then((instance: DataTables.Api) => {
				const dataArray = [];
				const instanceData = instance.rows().data();

				for (const key in instanceData) {
					if (!isNaN(Number(key))) {
						dataArray.push(instanceData[key]);
						continue;
					} else {
						break;
					}
				}
				return dataArray;
			})
		);
	}

	get dataTableLanguage() {
		// https://cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json
		return {
			select: {
				rows: '' // https://datatables.net/reference/option/language.select.rows
			},
			info: 'Mostrando de _START_ até _END_ de _TOTAL_ registro(s)',
			// info: 'Mostrando de _START_ até _END_ de' + this.totalRegistros + 'registro(s)',
			infoEmpty: 'Mostrando 0 até 0 de 0 registros',
			zeroRecords: 'Nenhum registro encontrado',
			paginate: {
				first: 'Primeira',
				last: 'Ultima',
				previous: 'Anterior',
				next: 'Proxima'
			},
			// sEmptyTable: 'Nenhum registro encontrado',
			// sInfo: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
			// sInfoEmpty: 'Mostrando 0 até 0 de 0 registros',
			sInfoFiltered: '',
			// sInfoPostFix: '',
			// sInfoThousands: '.',
			// sLengthMenu: '_MENU_ resultados por página',
			// sLoadingRecords: 'Carregando...',
			// sProcessing: 'Processando...',
			// sZeroRecords: 'Nenhum registro encontrado',
			// sSearch: 'Pesquisar',
			// oPaginate: {
			// 	sNext: 'Próximo',
			// 	sPrevious: 'Anterior',
			// 	sFirst: 'Primeiro',
			// 	sLast: 'Último'
			// },
			// oAria: {
			// 	sSortAscending: ': Ordenar colunas de forma ascendente',
			// 	sSortDescending: ': Ordenar colunas de forma descendente'
			// }
		};
	}

	criptoID(value: any): string {
		return this.cryptoService.base64Encrypt(value);
	}

	resize($event) {
		this.resizeSubject.next('');
	}
}

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class EncryptService {

	// private encryptionKey: string = 'V1X5153RP51MPL35';

	constructor() { }

	base64Encrypt(value: string | number) {
		return btoa(String(value));
	}

	base64Decrypt(value: string) {

		try {
			return atob(value);
		} catch (error) {

			return null;
		}
	}

	encodeRequest(json: unknown) {

		if (!json) {

			return;
		}

		const encrypted = CryptoJS.AES.encrypt(JSON.stringify(json), CryptoJS.enc.Utf8.parse('5daf20f5Y7e13X46'), {

			keySize: 128,
			BlockSize: 128,
			iv: CryptoJS.enc.Utf8.parse('5daf20f5Y7e13X46'),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});

		return encrypted.toString();
	}

	decodeRequest(hash: string) { }
}

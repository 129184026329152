import { Injectable, Injector } from '@angular/core';
import { tap, switchMap, timeout, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

// Models
import { environment } from '../../../environments/environment';
import { UsuarioAutenticado } from '../../shared/models/usuario-autenticado.model';

// Providers
import { UsuarioAutenticadoService } from './usuario-autenticado.service';
import { TokenStorage } from './token-storage.service';
import { OverlayService } from './overlay.service';
import { UnidadeNegocioController } from './unidade-negocio-controller.service';
import { LoaderService } from '../../shared/components/loader/services/loader.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private returnUrl: string;
	readonly conexaoUrl: string = environment.conexaoApiUrl + '/api/';
	readonly cadastroUrl: string = environment.cadastroApiUrl + '/api/'
	private splitUrl: string[] = [];
	private url: string = '';

	constructor(
		private http: HttpClient,
		private injector: Injector,
		private usuarioAutenticadoService: UsuarioAutenticadoService,
		private tokenStorage: TokenStorage,
		private overlayService: OverlayService,
		private unidadeNegocioController: UnidadeNegocioController) {
		setTimeout(() => {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.returnUrl = event.url;
				}
			});
		}, 100);
	}

	private get router(): Router {
		return this.injector.get(Router);
	}

	login(dados: { Login: string, Senha: string, PalavraChave: string }): Observable<UsuarioAutenticado> {
		return this.http.post<any>(this.conexaoUrl + 'autenticar', dados).pipe(
			timeout(50000),
			switchMap(async r => {
				return await this.getUsuarioAutenticadoByToken(r.Token).toPromise();
			}),
			tap((usuario: UsuarioAutenticado) => {
				if (usuario && usuario.Token) {
					this.setAuthUser(usuario);
				}
			})
		);
	}

	reautenticar(IDUnidadeNegocio: number, loaderService: LoaderService, UrlRedirect?: string) {
		let dados = {
			IDUnidadeNegocio: IDUnidadeNegocio,
			Token: this.tokenStorage.getAccessToken(),
			PalavraChave: JSON.parse(localStorage.getItem(environment.localStorageKeys.credenciaisUsuario)).PalavraChave
		};

		if (!UrlRedirect) {
			UrlRedirect = '';
		}

		this.http.post(this.conexaoUrl + 'reautenticar', dados).pipe(
			timeout(30000),
			catchError((reason) => {
				loaderService.hide();
				console.log('reautenticar catchError', reason);
				this.overlayService.toast({ message: reason.statusText });
				this.logout();
				return EMPTY;
			})
		).subscribe((response: any) => {
			this.tokenStorage.setAccessToken(response.Token);
			this.refreshAuthUser(response.Token);
			this.unidadeNegocioController.alterarUnidadeNegocio(dados.IDUnidadeNegocio);
			if (UrlRedirect) {
				this.splitUrl = UrlRedirect.split('/');
				if (UrlRedirect.indexOf('editar') > -1 || UrlRedirect.indexOf('visualizar') > -1) {
					this.url = this.splitUrl[this.splitUrl.length - 3];
				}
				else {
					this.url = this.splitUrl[this.splitUrl.length - 1];
				}
				this.router.navigate(['/' + this.url]).then(() => { window.location.reload(); });
			}
			loaderService.hide();
		});
	}

	async refreshAuthUser(token?: string): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (!token) {
				token = this.tokenStorage.getAccessToken()
			}
			if (token) {
				this.getUsuarioAutenticadoByToken(token).subscribe(usuario => {
					this.setAuthUser(usuario);
					resolve();
				}, error => {
					this.logout();
					reject(error);
				});
			} else {
				this.logout();
				resolve();
			}
		});
	}

	private setAuthUser(usuario: UsuarioAutenticado) {
		this.usuarioAutenticadoService.atualizarUsuarioAutenticado(usuario);
	}

	private getUsuarioAutenticadoByToken(token): Observable<UsuarioAutenticado> {
		return this.http.post(this.conexaoUrl + 'dados-usuario', {}, {
			headers: new HttpHeaders({ Token: token })
		}).pipe(
			timeout(50000),
			switchMap(async (dadosUsuario: any) => {
				const unidadesNegocioEPermissoes: any[] = await Promise.all([
					this.http.get(this.cadastroUrl + 'unidade-negocio/0012', {
						headers: new HttpHeaders({ Token: token }),
						params: { 'filtros.iDUsuario': dadosUsuario.IDUsuario }
					}).toPromise(),

					this.http.get(this.cadastroUrl + 'usuario/0004', {
						headers: new HttpHeaders({ Token: token }),
						params: {
							'filtros.iDUsuario': dadosUsuario.IDUsuario,
							'filtros.iDUnidadeNegocio': dadosUsuario.UnidadeNegocio
						}
					}).toPromise()
				]);

				return {
					UnidadesNegocio: unidadesNegocioEPermissoes[0],
					Permissoes: unidadesNegocioEPermissoes[1],
					Token: token,
					...dadosUsuario
				};
			}));
	}

	logout() {
		localStorage.removeItem('accessToken');
		this.tokenStorage.clear();
		this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl } });
	}
}

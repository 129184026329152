import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Models
import { Funcionalidade } from '../models/funcionalidade.model';

@Injectable({
	providedIn: 'root'
})
export class UsuarioFuncionalidadeService {

	private funcionalidadeSubject: BehaviorSubject<Funcionalidade[]> = new BehaviorSubject<Funcionalidade[]>(null);

	constructor() {
	}

	watchFuncionalidades(): Observable<Funcionalidade[]> {

		return this.funcionalidadeSubject.asObservable();
	}

	atualizarFuncionalidades(funcionalidades: any[]) {
		this.funcionalidadeSubject.next(funcionalidades);
	}

	public get funcionalidades(): Funcionalidade[] {

		return this.funcionalidadeSubject.value;
	}

	public get funcionalidadesIDs(): Array<number> {

		return this.funcionalidades.map(element => element.IDFuncionalidade);
	}
}

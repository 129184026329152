import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { LoaderComponent } from './loader.component';
import { INgxLoadingConfig, ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';

@NgModule({
	declarations: [LoaderComponent],
	imports: [
		CommonModule,
		NgxLoadingModule.forRoot({
			fullScreenBackdrop: true,
			animationType: ngxLoadingAnimationTypes.wanderingCubes,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#ffffff',
			secondaryColour: '#ffffff',
			tertiaryColour: '#ffffff'
		} as INgxLoadingConfig)
	],
	exports: [
		LoaderComponent
	]
})
export class LoaderModule { }

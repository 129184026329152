// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// RxJS
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

// Providers
import { AuthService } from '../../../services/auth.service';
import { LoaderService } from '../../../../shared/components/loader/services/loader.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(private auth: AuthService,
		private modalService: NgbModal,
		private loaderService: LoaderService) { }

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					console.log('intercept erro', error);
					this.loaderService.dismissAll();

					if (error.status == 401) {
						this.modalService.dismissAll();
						console.error(error.status);
						console.error(error.message);
						this.auth.logout();
						return of(error.message);
					}
					console.error(error.status);
					console.error(error.message);
					return of(error.message);
					// console.log('--- end of response---');
				}
			)
		);
	}
}
